<template>
  <div>
    <b-modal size="sm" class="modal-size" hide-footer v-model="modal_rate" :title="data.name+' | '+data.date">
        <b-form id="Form" @submit.prevent="Validate">
              <b-row>
                <b-col md="12">
                  <b-form-group label="Hab. Disponibles:">
                    <b-form-input  type="number" class="text-center" v-model="rate.free"></b-form-input>
                    <small v-if="errors.free" class="form-text text-danger" >Ingrese un numero</small>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Precio x Noche:">
                    <b-form-input  type="number" step="any" class="text-right" v-model="rate.price"></b-form-input>
                    <small v-if="errors.price" class="form-text text-danger" >Ingrese un precio</small>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Estado:">
                    <b-form-select :options="state" class="text-center" v-model="rate.state"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group>
                    <b-button  type="submit" class="form-control" variant="primary">Guardar</b-button>
                  </b-form-group>
                </b-col>


              </b-row>
        </b-form>
    </b-modal>

    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>
<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import EventBus from "@/assets/js/EventBus";
var moment = require("moment");
import LoadingComponent from './../../pages/Loading'
export default {
  name: "ModalClient",
  components:{
    LoadingComponent
  },
  data() {
    return {
        isLoading:false,
        module: 'Rate',
        role:2,
        modal_rate:false,
        rate: {
          id_rate:0,
          id_room_type:0,
          date:'',
          free:0,
          reserved:0,
          price:0,
          state:1,
        },
        state:[{value:0,text:'Inactivo'},{value:1,text:'Activo'}],
        data:{
          id_room_type:0,
          date:'',
          name:'',
        },
        errors:{
          free:false,
          price:false,
        }
    };
  },
  created (){
 
  },
  mounted () {
    EventBus.$on('ModalRateShow', (data) => {
      this.data.id_room_type = data.id_room_type;
      this.data.date = data.date;
      this.data.name = data.name;
      this.modal_rate = true;
      this.isLoading = true;
      this.ViewRate();
    });
  },
  methods: {
    ViewRate,
    Validate,
    SaveRate,
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    }
  },
};

function ViewRate() {
  let me = this;
  let url = me.url_base + "rate/get-by-date";
  let data = {
    date : me.data.date,
    id_room_type: me.data.id_room_type
  };
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json",token: me.token, module: me.module,role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.rate.id_rate = response.data.result.id_rate;
        me.rate.id_room_type = response.data.result.id_room_type;
        me.rate.date = response.data.result.date;
        me.rate.free = response.data.result.free;
        me.rate.reserved = response.data.result.reserved;
        me.rate.price = response.data.result.price;
        me.rate.state = response.data.result.state;
      }

      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}


function Validate() {
  this.errors.free = this.rate.free.length == 0 ? true : false;
  this.errors.price = this.rate.price.length == 0 ? true : false;

  if (this.errors.free) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.price) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
 
  let me = this;

  if (!this.validate) {
    Swal.fire({
      title: "Esta seguro de modificar la tarifa ?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Estoy de acuerdo!",
    }).then((result) => {
      if (result.value) {
        this.SaveRate(me);
      }
    });

  }

}

function SaveRate(me) {
  let url = me.url_base + "rate/add";
  let data = me.rate;
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json",token: me.token, module: me.module,role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
      EventBus.$emit('RefreshListRate');
       Swal.fire({ icon: 'success', text: 'Se modifico la tarifa correctamente', timer: 3000,})
       me.modal_rate = false;
      }else{
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }

      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}
</script>
