<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Tarifas y Disponibilidades</strong>
          </CCardHeader>
          <CCardBody>
            <b-row>
                <b-col sm="12" md="4"></b-col>
                <b-col sm="12" md="2">
                  <b-form-group label="Desde :">
                    <b-form-input @change="ListRate" class="text-center" :max="to" type="date"  ref="to" v-model="from"></b-form-input>
                    <small v-if="errors.from" class="form-text text-danger" >Selccione una fecha</small>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2">
                  <b-form-group label="Hasta :">
                    <b-form-input @change="ListRate" class="text-center" :min="from" type="date"  ref="from" v-model="to"></b-form-input>
                    <small v-if="errors.to" class="form-text text-danger" >Selccione una fecha</small>
                  </b-form-group>
                </b-col>
            </b-row>

            <div class="w-100" v-for="(item, it) in data_table" :key="it">
              <b-card :title="item.name">
                <b-button type="button" @click="ModalRateBlockShow(item.id_room_type,item.name)" variant="primary">Editar por bloque</b-button>
                <div class="table-responsive table-overflow mt-3">
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th rowspan="3" class="text-center"></th>
                      <th v-for="(item, it) in header_table" :key="it" class="text-center">{{item.month}}</th>
                    </tr>
                    <tr>
                      <th v-for="(item, it) in header_table" :key="it"  class="text-center">{{item.day}}</th>
                    </tr>
                    <tr>
                      <th v-for="(item, it) in header_table" :key="it" class="text-center">{{item.date}}</th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr>
                      <td class="text-left"> Estado</td>
                      <td  v-for="(state, it1) in item.rates" :key="'state'+it1" class="text-center"> <b-button :variant="StateRate(state.state)" type="button" ></b-button></td>
                    </tr>
                    <tr>
                      <td class="text-left"> Disponible</td>
                      <td  class="text-center" v-for="(free, it1) in item.rates" :key="'free'+it1">{{free.free}}</td>
                    </tr>
                    <tr>
                      <td class="text-left"> Reservado</td>
                      <td class="text-center" v-for="(reserved, it2) in item.rates" :key="'reserved'+it2"> <b-button @click="ModalReservationsShow(reserved.id_room_type,reserved.date,item.name)" type="button" variant="primary">{{reserved.reserved}}</b-button> </td>
                    </tr>
                    <tr>
                      <td class="text-left"> Precio</td>
                      <td  class="text-right" v-for="(price, it3) in item.rates" :key="'price'+it3"> {{price.price}}</td>
                    </tr>
                    <tr>
                      <td class="text-left"> Editar</td>
                      <td  class="text-right" v-for="(rate, it4) in item.rates" :key="'rate'+it4"><b-button @click="ModalRateShow(rate.id_room_type,rate.date,item.name)" variant="primary" type="button" ><i class="fas fa-edit"></i></b-button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              </b-card>
            </div>
        

          <ModalRate/>
          <ModalReservation/>
          <ModalRateBlock/>
           <LoadingComponent :is-visible="isLoading"/>
        
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
</style>>
<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
var moment = require("moment");
import ModalRate from './components/ModalRate'
import ModalReservation from './components/ModalReservation'
import ModalRateBlock from './components/ModalRateBlock'
import LoadingComponent from './../pages/Loading'
import EventBus from "@/assets/js/EventBus";
export default {
  name: "UsuarioList",
  components:{
      ModalRate,
      ModalReservation,
      ModalRateBlock,
      LoadingComponent,
  },
  data() {
    return {
       isLoading:false,
      module:'Rate',
      from:moment(new Date()).local().format("YYYY-MM-DD"),
      to:moment().add(30, 'days').local().format("YYYY-MM-DD"),
      errors:{
        to:false,
        from:false,
      },
      data_table: [],
      header_table: [],
    };
  },
  mounted() {
    EventBus.$on('RefreshListRate', () => {
      this.ListRate();
    });  
    this.ListRate();
  },
  methods: {
    StateRate,
    ListRate,
    ModalRateShow,
    ModalRateBlockShow,
    ModalReservationsShow,

  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function ModalReservationsShow(id_room_type,date,name) {
 let data = {
    id_room_type:id_room_type,
    date:date,
    name:name
  }
  EventBus.$emit('ModalReservationShow',data);
}

function ModalRateBlockShow(id_room_type,name) {
  let data = {
    id_room_type:id_room_type,
    name:name
  }
  EventBus.$emit('ModalRateBlockShow',data);
}

function ModalRateShow(id_room_type,date,name) {
  let data = {
    id_room_type:id_room_type,
    date:date,
    name:name
  }
  EventBus.$emit('ModalRateShow',data);
}

function StateRate(state) {
  let class_state = '';
  switch (state) {
    case 0: class_state = "danger"; break;
    case 1: class_state = "success"; break;
    case 2: class_state = "warning"; break;
    default:break;
  }
  return class_state;
}

//listar usuario
function ListRate() {
  let me = this;
  let url = this.url_base + "rate/list/" + this.from + "/"+this.to;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: 1,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        
        me.header_table = response.data.result.header;
        me.data_table = response.data.result.result;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

</script>
