<template>
  <div>
    <b-modal size="xl" class="modal-size" hide-footer v-model="modal_reservation" :title="data.name +' | '+data.date">
        <div class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="5%" class="text-center">#</th>
                    <th width="8%" class="text-center">Nro Reserva</th>
                    <th width="35%" class="text-center">Huesped</th>
                    <th width="20%" class="text-center">email</th>
                    <th width="10%" class="text-center">Telefono</th>
                    <th width="5%" class="text-center">Total</th>
                    <th width="7%" class="text-center">Estado</th>
                    <th width="7%" class="text-center">F. Registro</th>
                    <th width="8%" class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in reservations" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center">{{ item.number }}</td>
                    <td class="text-left"> {{ item.name }}</td>
                    <td class="text-left"> {{ item.email }}</td>
                    <td class="text-left"> {{ item.phone }}</td>
                    <td class="text-right"> {{ item.total_price }}</td>
                    <td class="text-center">
                      <b-badge v-if="item.state == 0" variant="danger">Agotado</b-badge>
                      <b-badge v-if="item.state == 1" variant="warning">Abandonado</b-badge>
                      <b-badge v-if="item.state == 2" variant="warning">Realizado</b-badge>
                    </td>
                    <td class="text-center"> {{ item.created_at }}</td>
                    <td class="text-center">
                      <b-dropdown bloque size="sm" text="Acciones" right>
                        <b-dropdown-item @click="ViewReservationPage(item.id_reservation)">Ver</b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
    </b-modal>

    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>
<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import EventBus from "@/assets/js/EventBus";
var moment = require("moment");
import LoadingComponent from './../../pages/Loading'
export default {
  name: "ModalClient",
  components:{
    LoadingComponent
  },
  data() {
    return {
        isLoading:false,
        module: 'Rate',
        role:2,
        modal_reservation:false,
        reservations: [],
        data:{
          date:'',
          id_room_type:'',
          name:'',
        }
        
    };
  },
  created (){
 
  },
  mounted () {
    EventBus.$on('ModalReservationShow', (data) => {
      this.data.date = data.date;
      this.data.id_room_type = data.id_room_type;
      this.data.name = data.name;
      this.modal_reservation = true;
      this.isLoading = true;
      this.ViewReservation();
    });
  },
  methods: {
    ViewReservation,
    Validate,
    ViewReservationPage,
    SaveRate,
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    }
  },
};
function ViewReservationPage(id_reservation) {
  let route = this.$router.resolve({
    name: "ReservationView",
    params: { id_reservation: je.encrypt(id_reservation) },
  });
  window.open(route.href,'_blank');

}

function ViewReservation() {
  let me = this;
  let url = me.url_base + "reservation/reservation-by-date";
  let data = {
    date : me.data.date,
    id_room_type: me.data.id_room_type
  };
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json",token: me.token, module: me.module,role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.reservations = response.data.result;
      }else{
        me.reservations = [];
      }

      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}


function Validate() {
  this.errors.free = this.rate.free.length == 0 ? true : false;
  this.errors.price = this.rate.price.length == 0 ? true : false;

  if (this.errors.free) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.price) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
 
  let me = this;

  if (!this.validate) {
    Swal.fire({
      title: "Esta seguro de modificar la tarifa ?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Estoy de acuerdo!",
    }).then((result) => {
      if (result.value) {
        this.SaveRate(me);
      }
    });

  }

}

function SaveRate(me) {
  let url = me.url_base + "rate/add";
  let data = me.rate;
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json",token: me.token, module: me.module,role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
      EventBus.$emit('RefreshListRate');
       Swal.fire({ icon: 'success', text: 'Se modifico la tarifa correctamente', timer: 3000,})
       me.modal_reservation = false;
      }else{
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }

      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}
</script>
